import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "../../UI/ReactSelect/ReactSelect";
import { useSelector, useDispatch } from "react-redux";
import plusSvg from "../../assets/img/icons/plus.svg";
import { useDebouncedCallback } from "use-debounce";
import { LoadingOutlined, CheckCircleTwoTone } from "@ant-design/icons";

import {
  setAddThemeInputValue,
  openThemeAccordion,
  setActiveTask,
  getModule,
  appendTheme,
  appendTask,
  getTask,
  deleteTask,
  deleteTheme,
  editTheme,
  updateTheme,
  themeInputActive,
  editTask,
  setActiveTheme,
} from "../../redux/editor/editor.slice";

import Pointer from "../../components/Pointer";
import TaskEditor from "../../components/TaskEditor";
import Accordion from "../../components/Accordion";
import editIcon from "../../assets/img/icons/pen-square.svg";
import deleteIcon from "../../assets/img/icons/trash-2.svg";
import PresentationChart from "../../assets/img/icons/PresentationChart.svg";
import ListBullets from "../../assets/img/icons/ListBullets.svg";
import TrashIcon from "../../assets/img/icons/Trash.svg";

import { useLocation, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { getModulesList } from "../../redux/module/module.slice";
import { Button, message, Space, Spin, Tooltip } from "antd";
import NoConnect from "../../UI/NoConnect";

import "./FindReferences.scss";
import {
  setDescription,
  updateModuleDesc,
} from "../../redux/updateModuleEditor";
import { convertDataToJSX } from "../../components/TaskEditor/configuration.utils";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";

const FindReferences = () => {
  let courseId = null;
  const { id: moduleId } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const title = useSelector((state) => state.descModuleInEditor.title);
  const addThemeInputValue = useSelector(
    (state) => state.editor.addThemeInputValue
  );
  const module = useSelector((state) => state.editor.module);
  const openedAccordionThemeIds = useSelector(
    (state) => state.editor.openedAccordionThemeIds
  );
  const activeTask = useSelector((state) => state.editor.activeTask);
  const activeTheme = useSelector((state) => state.editor.activeTheme);
  const tasks = useSelector((state) => state.editor.tasks);
  const modulesList = useSelector((state) => state.modulesList.modulesList);
  const inputActive = useSelector((state) => state.editor.inputActive);
  const isLoading = useSelector((state) => state.editor).isTasksEditLoading;
  const [hoveredId, setHoveredId] = useState("");
  const [pointerValue, setPointerValue] = useState(null);
  const [moduleEditor, setModuleEditor] = useState(module?.order);

  const role = localStorage.getItem("role");

  const themeRef = useRef(null);
  courseId = module.course_id;

  const handleAddTask = (theme_id) => {
    const theme = module.themes.find((th) => th.id === theme_id);
    if (theme) {
      const newTask = {
        theme_id: theme_id,
        experience: "0",
        blocks: [
          {
            type: "header",
            data: {
              text: "Заголовок",
              level: 1,
            },
          },
        ],
      };

      dispatch(appendTask({ newTask: newTask }));
      // dispatch(setActiveTask(newTask));
    }
  };

  const handleSetActiveTheme = ({
    id,
    presentation,
    scenario,
    title,
    tasks,
  }) => {
    if (!openedAccordionThemeIds.includes(id)) {
      dispatch(setActiveTheme({ id, presentation, scenario, title }));

      if (activeTask.theme_id !== id) {
        dispatch(getTask({ id: tasks[0]?.id }));
      }
    }
  };

  const handleDeleteTask = (id) => dispatch(deleteTask(id));
  const handleEditTask = (id) => {
    if (activeTask?.id === String(id)) return;
    dispatch(getTask({ id }));
  };
  const handleDeleteTheme = (id) => dispatch(deleteTheme({ id }));
  const handleEditTitleClick = (id) => {
    dispatch(
      editTheme(
        module.themes.map((item) => {
          if (item.isEdit) {
            dispatch(
              updateTheme({
                id: item.id,
                order: item.order,
                title: item.title,
              })
            );
          }
          if (item.id === id) {
            return { ...item, isEdit: !item.isEdit };
          }

          return { ...item, isEdit: false };
        })
      )
    );
  };
  const handleTitleChange = (event, id) => {
    const newArr = module?.themes.map((item) =>
      item.id === id ? { ...item, title: event.target.value } : item
    );
    dispatch(editTheme(newArr));
  };
  const handleChangeModule = (param) => {};
  const handleAddTheme = () => {
    if (!addThemeInputValue) {
      dispatch(themeInputActive(!inputActive));
      return;
    }
    if (inputActive) {
      dispatch(
        appendTheme({
          title: addThemeInputValue,
          module_id: Number(module?.id),
        })
      );
      dispatch(themeInputActive(!inputActive));
    }
    dispatch(themeInputActive(!inputActive));
  };
  const handleBlurAccordion = () => {
    dispatch(
      editTheme(
        module.themes.map((item) => {
          if (item.isEdit) {
            dispatch(
              updateTheme({
                id: item.id,
                order: item.order,
                title: item.title,
              })
            );
          }
          return { ...item, isEdit: false };
        })
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (inputActive) {
        dispatch(
          appendTheme({
            title: addThemeInputValue,
            module_id: Number(moduleId),
          })
        );
        dispatch(themeInputActive(!inputActive));
      }
    }
  };

  const hadleEditPoints = () => {
    const obj = tasks.find(
      (item) => Number(item.id) === Number(activeTask?.id)
    );
    dispatch(
      editTask({
        ...obj,
        experience: pointerValue,
      })
    );

    setPointerValue(activeTask.experience);
  };

  const updateModule = useDebouncedCallback(() => {
    dispatch(updateModuleDesc({ id: module.id, title, order: module.order }));
  }, 1000);

  React.useEffect(() => {
    !modulesList && dispatch(getModulesList({ id: module.course_id }));
    dispatch(getModule({ id: moduleId }));
    setModuleEditor(module?.order);
  }, [dispatch, location.pathname, moduleId, courseId]);

  const [filesLoading, setFilesLoading] = useState({
    tasks: {
      Presentation: false,
      Scenario: false,
    },
    themes: {
      Presentation: false,
      Scenario: false,
    },
  });

  const presentationTypes = [
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const scenarioTypes = [
    "text/plain",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];

  const handleUploadFile = async (e, type, fileType) => {
    if (!type || !fileType)
      return message.error("Произошла ошибка при загрузке файла");

    const file = e.target.files[0];

    if (fileType === "Presentation" && !presentationTypes.includes(file.type))
      return message.error(
        "Недопустимый формат файла. Допустимые форматы: PDF, PPT, PPTX."
      );

    if (fileType === "Scenario" && !scenarioTypes.includes(file.type))
      return message.error(
        "Недопустимый формат файла. Допустимые форматы: PDF, TXT, DOC, DOCX."
      );

    setFilesLoading({ ...filesLoading, [type]: { [fileType]: true } });

    try {
      const formData = new FormData();
      formData.append("file", file);
      if (type === "tasks") {
        formData.append("task_id", activeTask.id);
      } else {
        formData.append("theme_id", activeTheme.id);
      }

      const { data } = await axios.post(
        `${apiUrl}/api/v2/${type}/upload${fileType}`,
        formData
      );

      if (type === "tasks") {
        dispatch(getTask({ id: activeTask.id }));
      } else {
        const key = fileType.toLowerCase();

        dispatch(
          editTheme(
            module?.themes?.map((item) => {
              if (item.id === activeTheme.id) {
                const newItem = { ...item, [key]: data.file_path };
                dispatch(setActiveTheme(newItem));

                return newItem;
              }
              return item;
            })
          )
        );
      }
    } catch (error) {
      message.error("Произошла ошибка при загрузке файла");
    } finally {
      setFilesLoading({ ...filesLoading, [type]: { [fileType]: false } });
    }
  };

  const handleDeleteFile = async (type, fileType) => {
    if (!type || !fileType)
      return message.error("Произошла ошибка при удалении файла");

    setFilesLoading({ ...filesLoading, [type]: { [fileType]: true } });

    try {
      const key = type === "tasks" ? "task_id" : "theme_id";
      const value = type === "tasks" ? activeTask.id : activeTheme.id;

      await axios.delete(`${apiUrl}/api/v2/${type}/delete${fileType}`, {
        params: {
          [key]: value,
        },
      });

      if (type === "tasks") {
        dispatch(getTask({ id: activeTask.id }));
      } else {
        const key = fileType.toLowerCase();

        dispatch(
          editTheme(
            module.themes.map((item) => {
              if (item.id === activeTheme.id) {
                const newItem = { ...item, [key]: "" };
                dispatch(setActiveTheme(newItem));

                return newItem;
              }
              return item;
            })
          )
        );
      }
    } catch (error) {
      message.error("Произошла ошибка при удалении файла");
    } finally {
      setFilesLoading({ ...filesLoading, [type]: { [fileType]: false } });
    }
  };

  const createUrl = (fileUrl) => {
    const fileUrlType = fileUrl.split(".")[fileUrl.split(".").length - 1];

    if (fileUrlType === "pdf") {
      return fileUrl;
    } else {
      return `https://docs.google.com/viewer?url=${fileUrl}&embedded=true`;
    }
  };

  const renderUploadBlock = (files, fileTitle, fileType) => {
    const hasFiles = files?.scenario || files?.presentation;

    return (
      <div className="find-references__files-wrap">
        {role === "teacher" && hasFiles && (
          <>
            <h2 className="find-references__files-title">
              Файлы {fileTitle} "{files.title}":
            </h2>
            <div className="find-references__files">
              {files?.presentation && (
                <Tooltip placement="bottom" title="Презентация" arrow={true}>
                  <Button className="find-references__files-button">
                    <a
                      href={createUrl(files.presentation)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={PresentationChart} alt="PresentationChart" />
                    </a>
                  </Button>
                </Tooltip>
              )}
              {files?.scenario && (
                <Tooltip placement="bottom" title="Сценарий" arrow={true}>
                  <Button className="find-references__files-button">
                    <a
                      href={createUrl(files.scenario)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ListBullets} alt="ListBullets" />
                    </a>
                  </Button>
                </Tooltip>
              )}
            </div>
          </>
        )}
        {role === "admin" && (
          <>
            <h2 className="find-references__files-title">
              Файлы {fileTitle} "{files.title}":
            </h2>
            <div
              className="find-references__files"
              style={{ gap: hasFiles && 12 }}
            >
              {files?.presentation ? (
                <div className="find-references__files-btns">
                  <Tooltip
                    placement="bottom"
                    title="Скачать презентацию"
                    arrow={true}
                  >
                    <Button className="find-references__files-button">
                      <a
                        href={createUrl(files.presentation)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Презе...
                      </a>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title="Удалить презентацию"
                    arrow={true}
                  >
                    <Button
                      className="find-references__files-button"
                      onClick={() => handleDeleteFile(fileType, "Presentation")}
                      icon={<img src={TrashIcon} alt="TrashIcon" />}
                      loading={filesLoading[fileType].Presentation}
                      style={{ width: 44 }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <Tooltip placement="bottom" title="Презентация" arrow={true}>
                  {filesLoading[fileType].Presentation ? (
                    <Button
                      className="find-references__files-button"
                      loading={filesLoading[fileType].Presentation}
                    />
                  ) : (
                    <label
                      htmlFor="uploadPresentation"
                      className="find-references__files-button"
                    >
                      <input
                        id="uploadPresentation"
                        type="file"
                        onChange={(e) =>
                          handleUploadFile(e, fileType, "Presentation")
                        }
                        style={{ display: "none" }}
                      />
                      <img src={PresentationChart} alt="PresentationChart" />
                    </label>
                  )}
                </Tooltip>
              )}
              {files?.scenario ? (
                <div className="find-references__files-btns">
                  <Tooltip
                    placement="bottom"
                    title="Скачать сценарий"
                    arrow={true}
                  >
                    <Button className="find-references__files-button">
                      <a
                        href={createUrl(files.scenario)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Сцен..
                      </a>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title="Удалить сценарий"
                    arrow={true}
                  >
                    <Button
                      className="find-references__files-button"
                      onClick={() => handleDeleteFile(fileType, "Scenario")}
                      loading={filesLoading[fileType].Scenario}
                      style={{ width: 44 }}
                      icon={<img src={TrashIcon} alt="TrashIcon" />}
                    />
                  </Tooltip>
                </div>
              ) : (
                <Tooltip placement="bottom" title="Сценарий" arrow={true}>
                  {filesLoading[fileType].Scenario ? (
                    <Button
                      className="find-references__files-button"
                      loading={filesLoading[fileType].Scenario}
                    />
                  ) : (
                    <label
                      htmlFor="uploadScenario"
                      className="find-references__files-button"
                    >
                      <input
                        id="uploadScenario"
                        type="file"
                        onChange={(e) =>
                          handleUploadFile(e, fileType, "Scenario")
                        }
                        style={{ display: "none" }}
                      />
                      <img src={ListBullets} alt="ListBullets" />
                    </label>
                  )}
                </Tooltip>
              )}
            </div>
            <div className="find-references__files-p-wrap">
              <p className="find-references__files-p">
                Формат презентаций pdf, ppt, pptx
              </p>
              <p className="find-references__files-p">
                Формат сценария pdf, doc, docx, txt
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="root">
      <div className="loadingContainer">
        <div className="loading">
          {isLoading === "succes" && (
            <>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Spin
                  indicator={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                  size="small"
                >
                  <div className="content" />
                </Spin>
              </Space>
              <span className="txt">Сохраненo</span>
            </>
          )}
          {isLoading === "loading" && (
            <>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Spin indicator={<LoadingOutlined />} size="small">
                  <div className="content" />
                </Spin>
              </Space>
              <span className="txt">Сохранение</span>
            </>
          )}
          {isLoading === "error" && <NoConnect />}
        </div>
      </div>

      <div className="items">
        <div className="left-section">
          <div className="select">
            <ReactSelect
              setModuleEditor={setModuleEditor}
              changeFunction={handleChangeModule}
              options={modulesList}
            >
              <span className="text-module">Модуль:</span>
              <span className="order-mudule">{moduleEditor}</span>
            </ReactSelect>
          </div>
          {role === "admin" && (
            <div className="description-module">
              <TextField
                value={title}
                onChange={(e) => {
                  dispatch(setDescription(e.target.value));
                  updateModule();
                }}
                className="text-field"
                id="outlined-multiline-static"
                multiline
                rows={2}
                placeholder="Описание модуля"
              />
            </div>
          )}
          <div className="accordion-theme-and-task">
            <h3 className="theme-and-tasks">Темы и задания</h3>
            {module?.themes?.map(
              ({ id, title, isEdit, tasks, presentation, scenario }) => {
                return (
                  <Accordion
                    onMouseDown={(e) => e.preventDefault()}
                    onBlur={handleBlurAccordion}
                    onClickTheme={() =>
                      handleSetActiveTheme({
                        id,
                        presentation,
                        scenario,
                        title,
                        tasks,
                      })
                    }
                    active={id === activeTheme?.id}
                    key={id}
                    rightSectionDelete={
                      role === "admin" && (
                        <button
                          className={"deleteIcon"}
                          onClick={() => handleDeleteTheme(id)}
                        >
                          <img src={deleteIcon} alt="deleteIcon" />
                        </button>
                      )
                    }
                    rightSectionMove={
                      role === "admin" && (
                        <button
                          className={"addTaskBtn"}
                          onClick={() => handleAddTask(id)}
                        >
                          <img src={plusSvg} alt="editIcon" />
                        </button>
                      )
                    }
                    rightSectionEdit={
                      role === "admin" && (
                        <button
                          className={"editButton"}
                          ref={themeRef}
                          onClick={() => {
                            handleEditTitleClick(id);
                          }}
                        >
                          <img src={editIcon} alt="editIcon" />
                        </button>
                      )
                    }
                    onToggle={() => {
                      if (openedAccordionThemeIds.includes(id))
                        dispatch(
                          openThemeAccordion(
                            openedAccordionThemeIds.filter(
                              (item) => item !== id
                            )
                          )
                        );
                      else
                        dispatch(
                          openThemeAccordion([...openedAccordionThemeIds, id])
                        );
                    }}
                    isOpened={openedAccordionThemeIds.includes(id)}
                    id={id}
                    isTitleEdit={isEdit}
                    onChangeTitle={handleTitleChange}
                    title={title}
                  >
                    <ul>
                      {tasks?.map((task) => {
                        if (id === task.theme_id) {
                          return (
                            <li
                              onClick={() => handleEditTask(task.id)}
                              className={`contentTask ${
                                Number(task.id) === Number(activeTask?.id) &&
                                "activeTask"
                              } `}
                              onMouseEnter={() => setHoveredId(task.id)}
                              onMouseLeave={() => setHoveredId("")}
                              key={task.id}
                            >
                              <span className="task">{task.title}</span>

                              {role === "admin" && task.id === hoveredId && (
                                <div className="buttons-actions">
                                  <button
                                    className="edit-task-btn"
                                    onClick={() => handleEditTask(task.id)}
                                  >
                                    <img src={editIcon} alt="close" />
                                  </button>
                                  <button
                                    className="delete-task-btn"
                                    onClick={() =>
                                      handleDeleteTask({
                                        task_id: task.id,
                                        theme_id: id,
                                      })
                                    }
                                  >
                                    <img src={deleteIcon} alt="close" />
                                  </button>
                                </div>
                              )}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </Accordion>
                );
              }
            )}
            {inputActive && (
              <input
                autoFocus
                className="add-theme-input"
                type="text"
                onKeyDown={handleKeyDown}
                value={addThemeInputValue}
                onChange={(e) =>
                  dispatch(setAddThemeInputValue(e.target.value))
                }
                disabled={role === "admin" ? false : true}
              />
            )}
            {role === "admin" && (
              <div className="addThemeButton" onClick={() => handleAddTheme()}>
                <div className="plus-img">
                  <img src={plusSvg} alt="" />
                </div>
                <span>Добавить тему</span>
              </div>
            )}
          </div>
          {activeTheme && renderUploadBlock(activeTheme, "темы", "themes")}
          {activeTask && (
            <>
              {renderUploadBlock(activeTask, "задания", "tasks")}
              <Pointer
                pointerValue={pointerValue}
                activeTaskPoint={activeTask}
                hadleEditPoints={hadleEditPoints}
                setPointerValue={setPointerValue}
              />
            </>
          )}
        </div>

        <div className="text-editor">
          {activeTask && (
            <>
              {role === "admin" ? (
                <TaskEditor
                  setPointerValue={setPointerValue}
                  activeTask={activeTask}
                  pointerValue={pointerValue}
                />
              ) : (
                <div>
                  {activeTask?.blocks && activeTask.blocks?.length ? (
                    activeTask?.blocks?.map((block) => convertDataToJSX(block))
                  ) : (
                    <h2>В задании пока ничего нет</h2>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default FindReferences;
