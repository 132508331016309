import React from "react";
import "./UserAdmin.scss";
import plusWhite from "../../assets/img/icons/plusWhite.svg";
import power from "../../assets/img/icons/power.svg";
import settings from "../../assets/img/icons/settings.svg";
import cart from "../../assets/img/icons/cart.svg";
import powerLittle from "../../assets/img/powerLittle.svg";
import littleUser from "../.././assets/img/little-user.svg";
import component from "../../assets/img/component.svg";
import redCart from "../../assets/img/trash-2.svg";

import Avatar from "../../components/Avatar/Avatar";
import { Input, Button, Space, Modal } from "antd";
import CustomFileInput from "../../components/CustomFileInput";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";

import axios from "axios";
import { apiUrl } from "../../api/ApiService";

import IconSearch from "../../assets/img/icons/iconSearch";

import { message } from "antd";
const { confirm } = Modal;

const UserAdmin = () => {
  const [listUser, setListUser] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedCount, setSelectedCount] = React.useState(0);
  const [groupOptions, setGroupOptions] = React.useState([]);
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);
  const [activeButton, setActiveButton] = React.useState("student");
  const [isCsvModalVisible, setIsCsvModalVisible] = React.useState(false);
  const [isCsvFinishedModalVisible, setIsCsvFinishedModalVisible] =
    React.useState({
      visible: false,
      success: 0,
      failed: 0,
    });

  const [isAddToGroupModalVisible, setIsAddToGroupModalVisible] =
    React.useState(false);
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedGroupForAdd, setSelectedGroupForAdd] = React.useState(null);
  const [selectedUsersForGroup, setSelectedUsersForGroup] = React.useState([]);
  const [isAllUsersSelected, setIsAllUsersSelected] = React.useState(false);

  const token = localStorage.getItem("token");

  const [courseList, setCourseList] = React.useState([]);

  const openCsvModal = () => {
    setIsCsvModalVisible(true);
  };

  const closeCsvModal = () => {
    setIsCsvModalVisible(false);
  };
  const handleCsvFileUpload = async (file) => {
    if (!file) {
      return console.error("No file selected or event object is undefined");
    }

    try {
      const formData = new FormData();
      formData.append("csv", file);

      const { created_users, failed_to_create_users } = (
        await axios.post(`${apiUrl}/api/v2/csv`, formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;

      setIsCsvFinishedModalVisible({
        visible: true,
        success: created_users.length || 0,
        failed: failed_to_create_users.length || 0,
      });

      setIsCsvModalVisible(false);

      if (created_users.length) {
        setListUser((prevListUser) => {
          return {
            ...prevListUser,
            data: {
              ...prevListUser.data,
              users_quantity:
                prevListUser.data.users_quantity + created_users.length,
              users: [...created_users, ...prevListUser.data.users],
            },
          };
        });
      }
    } catch (error) {
      console.error("Error uploading CSV file:", error);
    }
  };

  const openCreateGroupModal = () => {
    setIsCreateGroupModalVisible(true);
  };

  const closeCreateGroupModal = () => {
    setIsCreateGroupModalVisible(false);
  };

  const openAddToGroupModal = () => {
    setIsAddToGroupModalVisible(true);
  };

  const closeAddToGroupModal = () => {
    setIsAddToGroupModalVisible(false);
  };

  const handleDeleteUser = async (userId) => {
    confirm({
      title: "Подтвердите удаление пользователя",
      content: "Вы уверены, что хотите удалить этого пользователя?",
      okText: "Да",
      cancelText: "Отмена",
      onOk: async () => {
        try {
          await axios.post(
            `${apiUrl}/api/v2/users?_method=delete`,
            { users: [userId] },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          setListUser((prevListUser) => {
            const updatedData = prevListUser.data.users.filter(
              (user) => user.id !== userId
            );
            return {
              ...prevListUser,
              data: { ...prevListUser.data, users: updatedData },
            };
          });

          setSelectedCount(0);
          setSelectedUserIds((prevIds) =>
            prevIds.filter((id) => id !== userId)
          );

          message.success("Пользователь успешно удален");
        } catch (error) {
          console.error("Error deleting user", error);
          message.error("Не удалось удалить пользователя");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDeleteSelectedUsers = async () => {
    confirm({
      title: "Подтвердите удаление выбранных пользователей",
      content: "Вы уверены, что хотите удалить выбранных пользователей?",
      okText: "Да",
      cancelText: "Отмена",
      onOk: async () => {
        try {
          await axios.post(
            `${apiUrl}/api/v2/users?_method=delete`,

            {
              users: selectedUserIds,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          setListUser((prevListUser) => {
            const updatedData = prevListUser.data.users.filter(
              (user) => !selectedUserIds.includes(user.id)
            );
            return {
              ...prevListUser,
              data: { ...prevListUser.data, users: updatedData },
            };
          });

          setSelectedCount(0);
          setSelectedUserIds([]);

          message.success("Выбранные пользователи успешно удалены");
        } catch (error) {
          console.log("Error deleting users", error);
          message.error("Не удалось удалить выбранных пользователей");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCreateGroup = async () => {
    try {
      const dataToSend = {
        name: groupName,
        course_id: selectedCourse.value,
      };

      const response = await axios.post(`${apiUrl}/api/v2/groups`, dataToSend, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const createdGroupId = response.data.id;

      const dataToAddToGroup = {
        group_id: createdGroupId,
        users: selectedUsersForGroup,
      };

      await axios.post(`${apiUrl}/api/v2/groups/bindUsers`, dataToAddToGroup, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsCreateGroupModalVisible(false);
      setGroupName("");
      setSelectedCourse(null);
      setSelectedUsersForGroup([]);
      message.success("Группа успешно создана");
    } catch (error) {
      message.error("Не удалось создать группу");
      console.log("Error creating or adding to group", error);
    }
  };

  const handleAddToGroup = async () => {
    try {
      const dataToAddToGroup = {
        group_id: selectedGroupForAdd?.[0]?.value || selectedGroupForAdd.value,
        users: selectedUsersForGroup,
      };

      await axios.post(`${apiUrl}/api/v2/groups/bindUsers`, dataToAddToGroup, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsAddToGroupModalVisible(false);
      setSelectedGroupForAdd(null);
      setSelectedUsersForGroup([]);
      message.success("Пользователи успешно добавлены в группу");
    } catch (error) {
      message.error("Не удалось добавить пользователей в группу");
      console.log("Error adding users to group", error);
    }
  };

  const toggleUserDisabled = async (userId) => {
    setListUser((prevListUser) => {
      return {
        ...prevListUser,
        data: {
          ...prevListUser.data,
          users: prevListUser.data.users.map((user) =>
            user.id === userId
              ? { ...user, isDisabled: !user.isDisabled }
              : user
          ),
        },
      };
    });

    try {
      await axios.post(
        `${apiUrl}/api/v2/users/activate`,
        { users: [userId] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      message.success("Пользователь успешно деактивирован");
    } catch (error) {
      console.log("Error deactivating user", error);
      message.error("Не удалось деактивировать пользователя");
    }
  };

  const handleToggleEnabled = async (userId) => {
    setListUser((prevListUser) => {
      return {
        ...prevListUser,
        data: {
          ...prevListUser.data,
          users: prevListUser.data.users.map((user) =>
            user.id === userId
              ? { ...user, isDisabled: !user.isDisabled }
              : user
          ),
        },
      };
    });
    try {
      await axios.post(
        `${apiUrl}/api/v2/users/activate`,
        { users: [userId] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      message.success("Пользователь успешно активирован ");
    } catch (error) {
      console.log("Error activating user", error);
      message.error("Пользователя не получилось деактивировать ");
    }
  };

  const handleDeactivateSelectedUsers = async () => {
    Modal.confirm({
      title: "Подтверждение",
      content: "Вы уверены, что хотите деактивировать выбранных пользователей?",
      onOk: async () => {
        try {
          await Promise.all(
            selectedUserIds.map(async (userId) => {
              try {
                await axios.post(
                  `${apiUrl}/api/v2/users/activate`,
                  { users: [userId] },
                  {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  }
                );
              } catch (error) {
                console.log(`Error deactivating user ${userId}`, error);
              }
            })
          );

          setListUser((prevListUser) => {
            const updatedData = prevListUser.data.users.map((user) => ({
              ...user,
              isDisabled: selectedUserIds.includes(user.id),
            }));
            return {
              ...prevListUser,
              data: { ...prevListUser.data, users: updatedData },
            };
          });
          setSelectedUserIds([]);
          setSelectedCount(0);

          message.success("Выбранные пользователи успешно деактивированы");
        } catch (error) {
          console.log("Error deactivating users", error);
          message.error("Не удалось деактивировать выбранных пользователей");
        }
      },
      onCancel: () => {},
    });
  };

  const toggleSelectedUserForGroup = (userId) => {
    if (selectedUsersForGroup.includes(userId)) {
      setSelectedUsersForGroup(
        selectedUsersForGroup.filter((id) => id !== userId)
      );
    } else {
      setSelectedUsersForGroup([...selectedUsersForGroup, userId]);
    }
  };

  React.useEffect(() => {
    const fetchUserList = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/v2/users?role=${activeButton}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setListUser(data);
      } catch (error) {
        console.log("Error", error);
      }
    };
    fetchUserList();
  }, [activeButton]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/v2/users?role=${activeButton}&q=${searchQuery}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setListUser(data);
      } catch (error) {
        console.log("Error fetching users", error);
      }
    };
    fetchUsers();
  }, [searchQuery]);
  React.useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v2/courses`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setCourseList(response.data);
      } catch (error) {
        console.log("Error fetching courses", error);
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v2/groups`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        const mappedOptions = response.data.groups.map((group) => ({
          value: group.id,
          label: group.name,
        }));

        setGroupOptions(mappedOptions);
      } catch (error) {
        console.log("Error fetching groups", error);
      }
    };
    fetchCourses();
    fetchGroups();
  }, []);

  return (
    <div className="table">
      <div className="block">
        <div className="button_group">
          <div className="buttons">
            <span style={{ display: "flex", gap: "8px" }}>
              <button
                className={activeButton === "student" ? "active" : ""}
                onClick={() => setActiveButton("student")}
              >
                Студенты
              </button>
              <button
                className={activeButton === "teacher" ? "active" : ""}
                onClick={() => setActiveButton("teacher")}
              >
                Преподаватели
              </button>
            </span>
            <div className="button-group-admins">
              <Input
                style={{ background: "#f0f0f0", border: "0px", width: "280px" }}
                placeholder="Быстрый поиск"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                prefix={<IconSearch />}
              ></Input>
              {activeButton !== "teacher" && (
                <Space wrap>
                  <Button
                    type="primary"
                    style={{ background: "#2898EC", color: "white" }}
                    onClick={openCsvModal}
                  >
                    <img src={plusWhite} alt="Add" />
                    <p> Добавить через CSV</p>
                  </Button>
                </Space>
              )}

              <Link to="/add-user">
                <Space wrap>
                  <Button
                    type="primary"
                    style={{ background: "#2898EC", color: "white" }}
                  >
                    <img src={plusWhite} alt="Add" />
                    <p> Добавить пользователя</p>
                  </Button>
                </Space>
              </Link>
            </div>
          </div>
        </div>

        <div className="table-groups ">
          <table className="table-users" style={{ margin: "0", width: "100%" }}>
            <tr className="tr">
              <th className="th" style={{ width: "44px" }}>
                <input
                  type="checkbox"
                  checked={isAllUsersSelected}
                  onChange={() => {
                    setIsAllUsersSelected(!isAllUsersSelected);
                    if (!isAllUsersSelected) {
                      const allUserIds = listUser.data.users
                        ? listUser.data.users.map((user) => user.id)
                        : [];
                      setSelectedUserIds(allUserIds);
                      setSelectedCount(allUserIds.length);
                    } else {
                      setSelectedUserIds([]);
                      setSelectedCount(0);
                    }
                  }}
                />
              </th>
              <th className="th">
                {activeButton === "student" ? "Студенты" : "Преподаватели"}

                <span className="th-group">
                  {listUser.data
                    ? `${listUser.data.users_quantity} `
                    : "0 участников"}
                </span>
              </th>
              <th className="th">Школа</th>
              <th className="th">Номер</th>
              <th className="th">Электронный адрес</th>
              <th className="th">Действия</th>
            </tr>

            <div className="lists-users">
              {listUser.data && listUser.data.users
                ? listUser.data.users.map((obj) => {
                    return (
                      <tr
                        className={`tr user-change ${
                          obj.isDisabled ? "disabled" : ""
                        } ${obj.deleted_at ? "disabled" : ""}`}
                        key={obj.id}
                      >
                        <td
                          className="td td-checkbox"
                          style={{ width: "44px" }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedUserIds.includes(obj.id)}
                            onChange={(e) => {
                              const userId = obj.id;
                              if (e.target.checked) {
                                setSelectedCount((prevCount) => prevCount + 1);
                                setSelectedUserIds((prevIds) => [
                                  ...prevIds,
                                  userId,
                                ]);
                                toggleSelectedUserForGroup(userId);
                              } else {
                                setSelectedCount((prevCount) =>
                                  Math.max(prevCount - 1, 0)
                                );
                                setSelectedUserIds((prevIds) =>
                                  prevIds.filter((id) => id !== userId)
                                );
                              }
                            }}
                          />
                        </td>
                        <td className="td td-name">
                          <Avatar width1 imageSrc={obj.image_path}>
                            {obj.name?.split(" ")[1].slice(0, 1) ??
                              obj.name.slice(0, 1)}
                          </Avatar>
                          <p style={{ marginLeft: "12px", width: "145px" }}>
                            {obj.name.slice(0, 14)}...
                          </p>
                        </td>
                        <td className="td td-school">{obj.school_id}</td>
                        <td className="td td-phone_number">
                          {obj.phone_number}
                        </td>
                        <td className="td td-email">
                          {obj.email.slice(0, 20)}...
                        </td>
                        <td className="td td-action">
                          {obj.isDisabled ? (
                            <img
                              src={power}
                              alt="Toggle Enabled"
                              onClick={() => handleToggleEnabled(obj.id)}
                            />
                          ) : (
                            <img
                              src={power}
                              alt="Toggle Disabled"
                              onClick={() => toggleUserDisabled(obj.id)}
                            />
                          )}
                          <Link
                            style={{ height: "24px" }}
                            to={`/settings/${obj.id}`}
                          >
                            <img src={settings} alt="Settings" />
                          </Link>
                          <img
                            src={cart}
                            alt="Delete"
                            onClick={() => handleDeleteUser(obj.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </div>
          </table>
        </div>
        <hr className="hr-info" />
        <div className="setting-down">
          <p>Выделено: {selectedCount}</p>
          <div className="button-group">
            <button onClick={openCreateGroupModal}>
              <img src={littleUser} alt="Create Group" />
              Создать группу
            </button>
            <button onClick={openAddToGroupModal}>
              <img src={component} alt="Add to Group" />
              Добавить в группу
            </button>
            <button onClick={handleDeactivateSelectedUsers}>
              <img src={powerLittle} alt="Deactivate" />
              Вкл/Выкл
            </button>
            <button
              style={{ color: "red" }}
              onClick={handleDeleteSelectedUsers}
            >
              <img src={redCart} alt="Delete" />
              Удалить
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="Создание группы"
        visible={isCreateGroupModalVisible}
        onCancel={closeCreateGroupModal}
        footer={[
          <Button key="cancel" onClick={closeCreateGroupModal}>
            Отмена
          </Button>,
          <Button key="create" type="primary" onClick={handleCreateGroup}>
            Создать
          </Button>,
        ]}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1", marginRight: "10px" }}>
            <Input
              placeholder="Название группы"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              style={{ height: "40px" }}
            />
          </div>
          <div style={{ flex: "1" }}>
            <ReactSelect
              classNamePrefix="react-select-group"
              options={courseList.map((course) => ({
                value: course.id,
                label: course.full_name,
              }))}
              value={selectedCourse}
              onChange={setSelectedCourse}
              placeholder="Выберите"
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Добавление в группу"
        visible={isAddToGroupModalVisible}
        onCancel={closeAddToGroupModal}
        footer={[
          <Button key="cancel" onClick={closeAddToGroupModal}>
            Отмена
          </Button>,
          <Button key="add" type="primary" onClick={handleAddToGroup}>
            Добавить
          </Button>,
        ]}
      >
        <ReactSelect
          options={groupOptions}
          value={selectedGroupForAdd}
          onChange={setSelectedGroupForAdd}
          placeholder="Выберите группу"
        />
      </Modal>

      <Modal
        title="Добавление через CSV"
        open={isCsvModalVisible}
        onCancel={closeCsvModal}
        onOk={handleCsvFileUpload}
        footer={null}
      >
        <CustomFileInput onFileSelect={handleCsvFileUpload} />
      </Modal>

      <Modal
        title="Загрузка файла завершена"
        open={isCsvFinishedModalVisible.visible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsCsvFinishedModalVisible({
            visible: false,
            success: 0,
            failed: 0,
          });
        }}
      >
        <div>
          <p>Добавлено пользователей: {isCsvFinishedModalVisible.success}</p>
          <p>
            Не удалось добавить пользователей:{" "}
            {isCsvFinishedModalVisible.failed}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default UserAdmin;
