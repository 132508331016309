import Avatar from "../../../components/Avatar/Avatar";
import React from "react";
import hackcoins from "../../../assets/img/icons/hackcoins.svg";
import trophy1 from "../../../assets/img/icons/trophy1.svg";
import trophy2 from "../../../assets/img/icons/trophy2.svg";
import trophy3 from "../../../assets/img/icons/trophy3.svg";
import "./students.scss";

const StudentsTable = ({ requestType, currentUser = {}, obj, index }) => {
  const rating = (index) => {
    switch (index) {
      case 1:
        return (
          <div className="trophy">
            <img src={trophy1} alt="trophy1" />
          </div>
        );
      case 2:
        return (
          <div className="trophy">
            <img src={trophy2} alt="trophy2" />
          </div>
        );
      case 3:
        return (
          <div className="trophy">
            <img src={trophy3} alt="trophy3" />
          </div>
        );
      default:
        return <p className="list_number">{index}</p>;
    }
  };

  function returnName(nameObj) {
    if (nameObj) {
      let name = "";
      for (let i = 0; i < nameObj.split(" ").length; i++) {
        name += `${
          nameObj.split(" ")[i].slice(0, 1).toUpperCase() +
          nameObj.split(" ")[i].slice(1)
        } `;
      }
      return name.trim();
    }
  }

  return currentUser.id === obj.id ? (
    <>
      <div className="students_table current-user">
        <div className="number_name">
          {rating(index)}
          <Avatar width1 imageSrc={obj.image_path}>
            {obj.name?.split(" ")[1].slice(0, 1)}
          </Avatar>
          <p>{returnName(obj.name)}</p>
        </div>
        <div className="point">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6667 11.5839V5.91604C14.6665 5.66756 14.6022 5.42352 14.4803 5.20839C14.3584 4.99326 14.1832 4.81462 13.9723 4.69038L9.11119 1.85646C8.90005 1.7321 8.66055 1.66663 8.41675 1.66663C8.17295 1.66663 7.93344 1.7321 7.7223 1.85646L2.86119 4.69038C2.65026 4.81462 2.47507 4.99326 2.35318 5.20839C2.23129 5.42352 2.167 5.66756 2.16675 5.91604V11.5839C2.167 11.8324 2.23129 12.0764 2.35318 12.2915C2.47507 12.5067 2.65026 12.6853 2.86119 12.8095L7.7223 15.6435C7.93344 15.7678 8.17295 15.8333 8.41675 15.8333C8.66055 15.8333 8.90005 15.7678 9.11119 15.6435L13.9723 12.8095C14.1832 12.6853 14.3584 12.5067 14.4803 12.2915C14.6022 12.0764 14.6665 11.8324 14.6667 11.5839Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.1805 6.63159L18.139 7.19037C18.3499 7.31461 18.5251 7.49325 18.647 7.70838C18.7689 7.92351 18.8332 8.16755 18.8334 8.41604V14.0839C18.8332 14.3323 18.7689 14.5764 18.647 14.7915C18.5251 15.0066 18.3499 15.1853 18.139 15.3095L13.2779 18.1434C13.0667 18.2678 12.8272 18.3333 12.5834 18.3333C12.3396 18.3333 12.1001 18.2678 11.889 18.1434L10.8931 17.5629"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.33325 6.66663L10.4999 10.8333"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10.5 6.66663L6.33333 10.8333"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>

          <p>{obj[requestType]}</p>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="students_table">
        <div className="number_name">
          {rating(index)}
          <Avatar width1 imageSrc={obj.image_path}>
            {obj.name?.split(" ")[1].slice(0, 1)}
          </Avatar>
          <p>{returnName(obj.name)}</p>
        </div>
        <div className="point">
          <img src={hackcoins} alt="hackcoins" />
          <p>{obj[requestType]}</p>
        </div>
      </div>
    </>
  );
};
export default StudentsTable;
